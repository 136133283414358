import styles from '../styles/location-hotspot.module.scss';

export const locationHotSpotCreator = (hotSpotDiv, args) => {
    const elP = document.createElement('p');
    elP.classList.add(styles['change-room']);
    const indexScene = args.item.indexScene;
    elP.innerText =
        args.photos[indexScene]?.title_room?.title ||
        'Не удалось получить название комнаты';
    hotSpotDiv.appendChild(elP);
};
