import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import { Pannellum } from 'pannellum-react';
import { isMobile, isTablet } from 'react-device-detect';
import {
    InfoCircleOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
} from '@ant-design/icons';
import { api, gqls } from '../../Services';
import config from '../../Config';
import RenderHotspot from './helpers/Hotspot';

import { Loading } from '../../components/Loading/Loading';
import styles from './main.module.scss';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Popover } from 'antd';
import contentSocial from './helpers/contentSocialButton';
import { useSearchParams } from 'react-router-dom';

const ViewProduct = lazy(() => import('./helpers/view-product'));
const LayoutVT = lazy(() => import('./helpers/layout-vt'));

let iconSocialTour = `${config.public_url}/uploads/social-blue_2.svg`;
let logoLGOWhite = `${config.public_url}/files/2023/06/26/1687772120439.svg`;

const Viewer = () => {
    const [searchParams] = useSearchParams();
    const refMain = useRef(null);
    const params = useParams();

    const stylesEye = searchParams.get('from') && searchParams.get('from') === 'bigroup' ? {
        position: 'fixed',
        top: 18,
        left: 24,
        zIndex: 99999
    } : {}

    const [photos, setPhotos] = useState([]);
    const [indexPhoto, setIndexPhoto] = useState(0);
    const [isVisibleInterface, setIsVisibleInterface] = useState(true);
    const [productArgs, setProductArgs] = useState(null);

    const { loading, data } = useQuery(gqls.virtualTours.getVTByID, {
        variables: {
            id: params.id,
        },
        onCompleted: (data) => {
            setPhotos(data?.getVirtualTourByID?.photos || []);
        },
    });

    useEffect(() => {
        if (
            !loading &&
            data?.getVirtualTourByID?.type === 'apartment_template'
        ) {
            api.analytics.trigger(
                data?.getVirtualTourByID?._id,
                'VIEW_PRODUCT',
                productArgs?.item?.product?._id,
                indexPhoto
            );
        }
    }, [productArgs]);

    useEffect(() => {
        if (
            !loading &&
            data?.getVirtualTourByID?.type === 'apartment_template'
        ) {
            api.analytics.trigger(
                data?.getVirtualTourByID?._id,
                'SHOW_PRODUCT',
                null,
                indexPhoto
            );
        }
    }, [indexPhoto, loading]);

    const share3DTour = () => {
        api.viewer.triggerStat({
            id: searchParams.get('widget_id') || '',
            type: 'share_3d_tour',
        });
    }

    return (
        <div className="main-block">
            {loading ? (
                <div className="main-block no-content-block">
                    <Loading />
                </div>
            ) : (
                <></>
            )}

            {!loading && !photos.length ? (
                <div className="main-block no-content-block">
                    <InfoCircleOutlined className="icon-no-found" />
                    <h2>3D Тур пока не доступен.</h2>
                </div>
            ) : (
                <></>
            )}

            {!loading && data?.getVirtualTourByID && photos.length ? (
                <>
                    <div className={styles.controllButtons}>

                        {
                            searchParams.get('lg-realestate-url') && (
                                <button
                                    type="button"
                                    className={styles.eyeButton}
                                >
                                    <Popover 
                                        trigger={['click']} 
                                        title="Поделиться" 
                                        placement={'bottomLeft'}
                                        content={()=>contentSocial(searchParams.get('lg-realestate-url'))}
                                        zIndex={9999999999}
                                        onClick={share3DTour}
                                    >
                                        <div>
                                            <img style={{
                                                width: 20
                                            }} src={iconSocialTour} />
                                        </div>
                                    </Popover>
                                </button>
                            )
                        }

                        <button
                            type="button"
                            className={styles.eyeButton}
                            onClick={() =>
                                setIsVisibleInterface(!isVisibleInterface)
                            }
                            style={stylesEye}
                        >
                            {isVisibleInterface ? (
                                <EyeOutlined />
                            ) : (
                                <EyeInvisibleOutlined />
                            )}
                        </button>

                    </div>

                    <Suspense>
                        <ViewProduct
                            vt={data?.getVirtualTourByID}
                            styles={styles}
                            product={productArgs}
                            api={api}
                        />
                    </Suspense>

                    {!!isVisibleInterface && (
                        <>
                            <Suspense>
                                <LayoutVT
                                    setIsVisibleInterface={setIsVisibleInterface}
                                    isVisibleInterface={isVisibleInterface}
                                    refMain={refMain}
                                    indexPhoto={indexPhoto}
                                    setIndexPhoto={setIndexPhoto}
                                    vt={data?.getVirtualTourByID}
                                    budget={data?.getVirtualTourByID?.budget}
                                />
                            </Suspense>
                        </>
                    )}
                </>
            ) : (
                <></>
            )}

            {
                !!(searchParams.get('from') && searchParams.get('from') === 'bigroup') && (
                    <div className={styles.copyBlock}>
                        <p>Powered by</p>
                        <img src={logoLGOWhite} />
                    </div>
                )
            }

            {!loading && photos.length && (
                <Pannellum
                    ref={refMain}
                    width="100vw"
                    height="100vh"
                    autoRotate={2.4}
                    image={`${config.public_url}${
                        photos[indexPhoto]?.url || ''
                    }`}
                    pitch={3}
                    yaw={55}
                    hfov={isTablet ? 125 : isMobile ? 95 : 125}
                    autoLoad
                    showControls={false}
                    hotspotDebug={false}
                    onLoad={() => {
                        //setTourLoading(false);
                    }}
                >
                    {photos[indexPhoto]?.vr_data.map((item) =>
                        RenderHotspot({
                            item,
                            photos,
                            setIndexPhoto,
                            indexPhoto,
                            api,
                            Hotspot: Pannellum.Hotspot,
                            setProductArgs,
                        })
                    )}
                </Pannellum>
            )}
        </div>
    );
};

export default Viewer;
