import { gql } from '@apollo/client';

const getBudgetOfFlatTemplate = gql`
    query ($flatTemplateId: String) {
        getBudgetOfFlatTemplate(flat_template_id: $flatTemplateId) {
            FlatTemplate {
                title
            }
        }
    }
`;

export default {
    getBudgetOfFlatTemplate,
};
