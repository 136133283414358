import React from 'react';
import icons from '../../assets/icons';
import styles from './loader.module.scss';

export const Loading = () => {
  return (
    <div className={styles.loadingImgContainer}>
      <div className={styles.imgContainer}>
        <img className={styles.loadingBlinkImg} src={icons.logoLocation} alt="" />
      </div>
      <span className={styles.loadingText}>Загрузка...</span>
    </div>
  );
};
