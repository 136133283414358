import { CodeSandboxCircleFilled } from '@ant-design/icons';

class Snowflake {
    constructor(context) {
        this.self = context;

        this.x = Math.random() * this.self.w;
        this.y = Math.random() * this.self.h;
        this.posX = this.x;

        this.ctx = context.ctx;
        this.radius = Math.random() * 2.9;
        this.speed = Math.random() * 0.24;
        this.alpha = Math.random();
    }

    draw() {
        this.posX = this.posX + 0.15;
        this.y += this.speed;
        this.x += 0.02;

        if (this.y > this.self.h - 10) {
            this.y = 0;
            this.speed = Math.random() * 0.34;
            this.x = Math.random() * this.self.w;
            this.alpha = Math.random();
        }

        if (this.x > this.posX) {
            this.x = this.posX - 0.23;
        }
        //rgb(224 244 252)
        this.ctx.fillStyle = `rgba(224,244,252, ${this.alpha})`;
        this.ctx.beginPath();
        this.ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        this.ctx.closePath();
        this.ctx.fill();
    }
}

export default Snowflake;
