import { Component } from 'react';
import styles from './ErrorBoundary.module.scss';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={styles.wrapper}>
                    <h2 className={styles.errorText}>Что-то пошло не так. Проблему уже решаем.</h2>
                </div>
            );
        }

        return this.props.children;
    }
}
