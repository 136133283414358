import { gql } from '@apollo/client';

export const getVTByID = gql`
    query ($id: String) {
        getVirtualTourByID(id: $id) {
            _id
            title
            secondary_title
            budget
            template_id {
                _id
                apartment_id
                title
                square_metres
                square_metre_price
                count_rooms
                total_price
                floor
                apartment_complex_id {
                    _id
                    title
                    slug
                    block_id {
                        _id
                        title
                    }
                    photos {
                        url
                    }
                    coords {
                        lat
                        lon
                    }
                    builder {
                        _id
                        title
                        logo {
                            url
                        }
                    }
                    sold
                }
            }
            realestate {
                _id
                title
                address
                photo {
                    url
                }
            }
            widget {
                _id
                title
                description
            }
            photos {
                _id
                url
                vr_data {
                    game
                    position {
                        pitch
                        yaw
                    }
                    type
                    indexScene
                    url
                    title
                    price
                    product {
                        _id
                        title
                        link
                        sync_status
                        price
                        discount_description
                        discount
                        article_number
                        instagram
                        shop {
                            title
                            phone
                            address
                            whatsapp
                            icon {
                                url
                            }
                        }
                        photo {
                            url
                        }
                        photos {
                            url
                        }
                        unit {
                            value
                        }
                    }
                }
                dott_for_room {
                    top
                    left
                }
                title_room {
                    title
                }
                size
                yaw_layout_diff
            }
            yaw
            hfov
            type
            layout {
                url
            }
            views
            author {
                email
            }
            createdAt
        }
    }
`;

export default {
    getVTByID,
};
