import whatsapp from './whatsapp.svg';
import logo from './logo.svg';
import close from './close.svg';
import inst from './inst.svg';
import newPoint from './new-point.svg';
import point from './point.svg';
import planSelect from './plan-select-icon.svg';
import logov2 from './logo-v2.svg';
import size from './size.svg';
import rightArrow from './arrow-right.svg';
import closeIcon from './closeInfo.svg';
import blueLogo from './blueLogo.svg';
import blueRightArrow from './rightBlueArrow.svg';
import arrowLeft from './arrow-left.svg';
import user from './user.svg';
import phone from './phone-blue.svg';
import web from './web-svgrepo-com.svg';
import crossIcon from './cross-icon.svg';
import logoLocation from './logo-location.svg';

export default {
    whatsapp,
    logo,
    close,
    inst,
    newPoint,
    point,
    planSelect,
    logov2,
    size,
    rightArrow,
    closeIcon,
    blueLogo,
    blueRightArrow,
    arrowLeft,
    user,
    phone,
    web,
    crossIcon,
    logoLocation,
};
