import Snowflake from './snowflake';

let statusAnimation;
let startAnimation = false;

class initCanvas {
    constructor(canvas, w, h) {
        this.canvas = canvas;
        this.w = w;
        this.h = h + 70;
        this.canvas.width = this.w;
        this.canvas.height = this.h;
        this.ctx = this.canvas.getContext('2d');
        this.snowflakes = null;

        this.init = this.init.bind(this);
    }

    init() {
        this.generateSnowflakes();
        this.loop();
    }

    loop() {
        this.ctx.clearRect(0, 0, this.w, this.h);
        this.snowflakes.map((snowflake) => snowflake.draw());
        if (startAnimation) {
            statusAnimation = requestAnimationFrame(() => this.loop.call(this));
        } else {
            cancelAnimationFrame(statusAnimation);
        }
    }

    generateSnowflakes() {
        this.snowflakes = new Array(130).fill(0).map(() => new Snowflake(this));
    }
}

const generateCanvas = (productID, hotSpotElement) => {
    if (productID) {
        const productEl = document.getElementById(productID);
        if (productEl) {
            const getProductWrapper = productEl.childNodes[5].childNodes[1];
            const createCNV = document.createElement('canvas');

            let createCnvConst = new initCanvas(
                createCNV,
                getProductWrapper.offsetWidth,
                getProductWrapper.offsetHeight
            );

            hotSpotElement.addEventListener('mouseenter', () => {
                createCNV.classList.add('product_cnv');
                getProductWrapper.appendChild(createCNV);
                startAnimation = true;
                createCnvConst.init();
                return;
            });

            hotSpotElement.addEventListener('mouseleave', () => {
                startAnimation = false;
            });
        }
    }
};

export default generateCanvas;
