import React from 'react';
import productHotspotCreator from './hotspot-icon';
import { getProductArguments } from './hotspot.helper';
import { locationHotSpotCreator } from './location-hotspot';

import '../styles/product-point-card.css';

const RenderHotspot = ({
    item,
    Hotspot,
    setIndexPhoto,
    photos,
    setProductArgs,
}) => {
    const handleProductClick = (_, args) => {
        setProductArgs(args);
    };

    if (item.type === 'NEW_SCENE') {
        return (
            <Hotspot
                key={item._id}
                type="custom"
                pitch={item.position.pitch}
                yaw={item.position.yaw}
                handleClick={() => {
                    setIndexPhoto(item.indexScene);
                }}
                tooltip={
                    photos[item.indexScene]?.title_room?.title &&
                    locationHotSpotCreator
                }
                tooltipArg={{ item, photos }}
            />
        );
    }

    const productArguments = getProductArguments(item);

    return (
        <Hotspot
            key={item._id}
            type="custom"
            pitch={item.position.pitch}
            yaw={item.position.yaw}
            URL={null}
            handleClick={handleProductClick}
            handleClickArg={{
                ...productArguments,
                setProductArgs,
            }}
            tooltipArg={{
                ...productArguments,
                setProductArgs,
            }}
            tooltip={productHotspotCreator}
        >
            <h2>{item.title}</h2>
        </Hotspot>
    );
};
export default RenderHotspot;
