import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer, Slide } from 'react-toastify';
import Viewer from './Containers/Viewer';
import Page404 from './Containers/404';
import View3D from './Containers/View3d';

import { client } from './Services/api/apollo';
import { ErrorBoundary } from './providers/ErrorBoundary/';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './Services/Styles/app.css';
import './Services/Styles/media.css';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Page404 />,
    },
    {
        path: '/:id',
        element: (
            <>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    transition={Slide}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    limit={5}
                    closeButton={false}
                />
                <Viewer />
            </>
        ),
    },
    {
        path: '/demo/3d',
        element: (
            <>
                <View3D />
            </>
        ),
    },
]);

const root = createRoot(document.getElementById('root'));

root.render(
    <ApolloProvider client={client}>
        <ErrorBoundary>
            <RouterProvider router={router} />
        </ErrorBoundary>
    </ApolloProvider>
);

serviceWorker.unregister();
