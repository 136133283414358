export const getProductArguments = (item) => ({
    title: item.product ? item.product.title : item.title ? item.title : '',
    price: item.product?.price ? item.product.price : item.price || '',
    url: item.product ? item.product.link : item.url ? item.url : '',
    discount: item.product ? item.product.discount : null,
    logoShop: item.product?.shop?.icon?.url || null,
    shopTitle: item.product?.title ? item.product?.shop?.title : null,
    statusLibrary: !!item.product,
    unitValue: item?.product?.unit?.value || '',
    item,
});
