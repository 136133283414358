import axios from 'axios';
import config from '../../Config';

axios.defaults.baseURL =
    process.env.NODE_ENV === 'development' ? config.dev_url : config.public_api;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] =
    'GET,PUT,POST,DELETE';

export default axios;
