import { ApolloClient, InMemoryCache } from '@apollo/client';
import config from '../../../Config';

const gql_url =
    process.env.NODE_ENV === 'development'
        ? `${config.dev_url}graphql`
        : `${config.public_api}graphql`;

export const client = new ApolloClient({
    uri: gql_url,
    cache: new InMemoryCache(),
});
