import axios from '../axios';

export default {
    trigger: async (vt_id, type, product_id, index_room = 1) => {
        if (vt_id) {
            await axios.post('/v1/analytics/360', {
                vt_id,
                index_room,
                type,
                product_id,
            });
        }
    },
};
