import config from '../../../Config';
import { Popover, Button } from 'antd';
import styles from '../main.module.scss';

const contentSocial = (url = '') => {

    let iconWhatsapp = `${config.public_url}/uploads/whatsapp.svg`;
    let iconTelegram = `${config.public_url}/uploads/telegram.svg`;

    const targetLink = (type_soc) => {

        let link = '';

        if(type_soc === 'whatsapp'){
            link = `https://wa.me/?text=%20${encodeURIComponent(url)}`
        } else if(type_soc === 'telegram'){
            link = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=`
        }

        return window.open(link, '_blank');

    }

    return (
        <div className={styles.socialContent}>
            <div className={styles.socialButtons}>
                <span onClick={()=>targetLink('whatsapp')}>
                    <img src={iconWhatsapp}/>
                    <p>Whatsapp</p>
                </span>
                <span onClick={()=>targetLink('telegram')}>
                    <img src={iconTelegram}/>
                    <p>Telegram</p>
                </span>
            </div>
            <div className={styles.socialUrl}>
                <span id="lg-url">{url}</span>
                <Popover trigger={['click']} content="Скопировано" zIndex={9999999999999}>
                    <Button type="primary" size="middle" className={styles.socialUrlButton} onClick={copyURL}>скопировать</Button>
                </Popover>
            </div>
        </div>
    )
}

const copyURL = () => {
    const element = document.querySelector('#lg-url');
    const storage = document.createElement('textarea');
    storage.value = element.innerText || element.textContent;
    element.appendChild(storage);

    storage.select();
    storage.setSelectionRange(0, 9999);
    document.execCommand('copy');
    element.removeChild(storage);
}

export default contentSocial;