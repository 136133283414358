import React from 'react';
import currencyFormatter from 'currency-formatter';
import { isMobile } from 'react-device-detect';
import config from '../../../Config';
import generateCanvas from './canvas';

const hotspotIcon = (hotSpotDiv, args) => {
    if (args?.item?.product) {
        const productID = `hotspotProduct_${args?.item?.product?._id}`;

        hotSpotDiv.innerHTML = `
            <div class="productPoint" id="${productID}">
                <div class="overlaybackHover"></div>
                <div class="productPointIcon"></div>
                <div class="productPointWrapper">
                    <div class="productPointContent">
                        <p>Магазин ${args?.item?.product?.shop?.title.substr(
                            0,
                            15
                        )}${
            args?.item?.product?.shop?.title.length >= 15 ? '...' : ''
        }</p>
                        <div class="productPointPhoto" style="background: url(${
                            config.public_url
                        }${
            args?.item?.product?.photo?.url
        }) center / 110% no-repeat;"></div>
                                        <h5>${args?.item?.product?.title.substr(
                                            0,
                                            25
                                        )}${
            args?.item?.product?.title.length >= 25 ? '...' : ''
        }</h5>
                        <h2>${
                            currencyFormatter.format(
                                args?.item?.product?.price,
                                {
                                    code: 'EUR',
                                    symbol: '',
                                }
                            ) + '₸'
                        }</h2>
                    </div>
                    <span>развернуть</span>
                </div>
            </div>
        `;

        if (!isMobile) {
            generateCanvas(productID, hotSpotDiv);
        }

    }
};

export default hotspotIcon;
