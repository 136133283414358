import React, { useRef, useEffect, useState } from 'react';

import { Three3D } from './three';
import styles from './styles/view.module.scss';

const View3D = () => {

    const elRef = useRef(null);
    const [name_model, set_name_model] = useState('modern_room/scene.gltf');
    const [percent_load, set_percent_load] = useState('');

    useEffect(()=>{

        if(elRef?.current){

            elRef.current.innerHTML = '';
            const three3d = new Three3D(elRef?.current, name_model, set_percent_load);
            three3d.init();

        }

    }, [elRef, name_model]);

    return (
        <div className={styles.view}>
            <div className={styles.examples}>
                <h5 onClick={()=>set_name_model('modern_room/scene.gltf')} >example #1</h5>
                <h5 onClick={()=>set_name_model('demo/scene.gltf')}>example #3</h5>
                <h5 onClick={()=>set_name_model('cat/scene.gltf')}>example #4</h5>
            </div>
            {
                Number(percent_load) < 100 ?
                    <div className={styles.loadBlock}>
                        <div className={styles.loadLineBlock}>
                            <div className={styles.loadLine} style={{
                                width: `${percent_load}%`
                            }}></div>
                        </div>
                    </div>
                :   <></>
            }
            
            <div ref={elRef}></div>
        </div>
    )
}

export default View3D;