import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

import * as dat from 'dat.gui';

let gui = new dat.GUI();

export class Three3D {
    constructor(element, name_model, percent_load){

        this.name_model = name_model;
        this.percent_load = percent_load;

        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(40, this.width / this.height, 1, 5000);

        this.hLight = new THREE.AmbientLight(0x111111, 30);
        this.dLight = new THREE.DirectionalLight(0xe8fc65, 0.4);
        this.pLight = new THREE.PointLight(0xd3d3d3, 1);

        this.render = new THREE.WebGLRenderer({
            antialias: true
        });

        this.element = element;

        this.loader = new GLTFLoader();
        this.controls = new OrbitControls(this.camera, this.render.domElement);

        this.model = null;

        gui.destroy();
        gui = new dat.GUI();

    }

    init(){

       this.dLight.position.set(27, 155, 18);
       this.dLight.castShadow = true;
       this.pLight.position.set(0, 100, 200);

        this.camera.rotation.y = 45/180 * Math.PI;
        this.camera.rotation.x = 800;
        this.camera.rotation.y = 100;
        this.camera.rotation.z = 1000;
        this.camera.position.set( 4, 15, 120 );

        this.scene.background = new THREE.Color(0x000000);

        this.scene.add(this.hLight);
        this.scene.add(this.dLight);
        this.scene.add(this.pLight);

        this.render.setSize(this.width, this.height);
        this.element.appendChild(this.render.domElement);

        this.loadModel();

        this.controllConsts();

    }

    controllConsts(){

        let folder_dLight = gui.addFolder('dLight');
        let folder_pLight = gui.addFolder('pLight');

        folder_dLight.add(this.dLight.position, 'y');
        folder_dLight.add(this.dLight.position, 'x');
        folder_dLight.add(this.dLight.position, 'z');
        folder_dLight.add(this.dLight.position, 'isVector3');

        folder_pLight.add(this.pLight.position, 'y');
        folder_pLight.add(this.pLight.position, 'x');
        folder_pLight.add(this.pLight.position, 'z');
        folder_pLight.add(this.pLight, 'intensity');
    }

    animate() {

        this.model.rotation.z -= 0.0002;

        requestAnimationFrame( () => this.animate() );
        this.render.render( this.scene, this.camera );
    
    }

    loadModel(){

        this.loader.load(`https://api.logovo.kz/models/${this.name_model}`, (gltf) => {

            const model = gltf.scene;

            this.model = model.children[0];
            this.model.scale.set(1, 1, 1);
            this.model.rotation.z = 16;
            
            this.scene.add( model );
            this.controls.update();
            this.animate();

        }, (xhr) => {
            this.percent_load(xhr.loaded / xhr.total * 100 );
        }, ( error ) => {

            console.log('An error happened ', error );
    
        });
    }

}