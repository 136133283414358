import { message } from 'antd';
import axios from '../axios';
import { toast } from 'react-toastify';

export default {
    triggerStat: async ({ id, type }) => {
        await axios.post(`v1/stats/trigger`, {
            id,
            type,
        });

        return true;
    },
    sendMail: async (flat_id, number_player, phone) => {
        if (number_player && phone) {
            try {
                await axios.post('v1/mail/send', {
                    type: 'GAME',
                    number_player,
                    number_phone: phone,
                    link: `https://viewer.logovo.kz/${flat_id}`,
                });

                return true;
            } catch (err) {
                message.warning('Ошибка, попробуйте позже');
                return false;
            }
        }

        message.warning('Заполните обязательные поля');
        return false;
    },
    getApartment: async (flat_id) => {
        try {
            let { data } = await axios.get(`v1/filter/apartment/${flat_id}`);

            return data;
        } catch (err) {
            return false;
        }
    },
    saveAnalytic: async (args) => {
        try {
            if (args.item.product) {
                await axios.post(`v1/analytics/save`, {
                    name: 'Переход в магазин',
                    type: 'CLICK',
                    type_page: '360',
                    shop_id:
                        args.item.product.shop && args.item.product.shop._id
                            ? args.item.product.shop._id
                            : args.item.product.shop,
                    product_id:
                        args.item.product && args.item.product._id
                            ? args.item.product._id
                            : args.item.product,
                });
            }
            return true;
        } catch (err) {
            return false;
        }
    },

    sendCallbackMail: async ({
        title,
        link,
        name,
        phone,
        email,
        builder,
        flat,
        realestate,
        widget_id,
        application_type,
        flat_id,
        widget_tab_type = '',
        utm_source = '',
        utm_medium = '',
        utm_campaign = '',
        redirect_success_application
    }) => {
        redirectSuccessApplication(redirect_success_application);
        return
        try {
            const { data } = await axios.post('v1/mail/send', {
                title,
                link,
                name,
                phone,
                email,
                builder,
                flat,
                realestate,
                widget_id,
                application_type,
                flat_id,
                utm_source,
                utm_medium,
                utm_campaign,
                widget_tab_type: application_type === 'widget' ? 'tour' : '',
            });
            toast.success(data);
            
        } catch (err) {
            toast.error(
                err?.response?.status === 400
                    ? err?.response?.data
                    : err?.response?.status === 422
                    ? err?.response?.data?.phone?.message
                    : 'Проверьте заполненые поля и попробуйте еще раз'
            );
        }
    },

    getComplexBySlug: async (slug) => {
        try {
            const { data } = await axios.get(
                `v1/filter/apartment/complex/${slug}`
            );

            return {
                statusReq: true,
                complex: data,
            };
        } catch (err) {
            return {
                statusReq: false,
            };
        }
    },

    getFilteredEntity: async (param) => {
        try {
            const { data } = await axios.post(`v1/filter`, param);
            return {
                statusReq: true,
                data,
            };
        } catch {
            return {
                statusReq: false,
            };
        }
    },
};

const redirectSuccessApplication = (url) => {

    if(url && url != ''){
        window.open(url);
    }

}
